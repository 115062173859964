




























































































































































































































@import "@/assets/css/project-variables.scss";
$inactive-color: rgb(144,147,153);

.orgchart-container button span {
  pointer-events: none;
}

.orgchart .node {
  width: 150px;
}

.node-big-block{
  display: flex;
}
.node-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 150px;
  border: 2px solid $inactive-color;
  background-color: white;
  height: 460px;

  &.active {
    border-color: $--color-primary;
    background-color: color-mix(in srgb, $inactive-color 6%, white);

    .node-block-fio{
      background-color: color-mix(in srgb, $--color-primary 6%, white);
      color: $--color-primary;
    }

    .node-block-position{
      background-color: color-mix(in srgb, $inactive-color 6%, white);
    }
  }
}

.node-block button {
  width: 100%;
}

.node-block-image {
  display: flex;
}

.node-block-fio {
  background-color: white;
  color: black;
  padding: 5px;
}

.node-block-position {
  font-size: 0.8rem;
  padding: 5px;
  background-color: white;
  color: $inactive-color;
}


.orgchart .lines .topLine {
  border-top: 2px solid $inactive-color;
}

.orgchart .lines .rightLine {
  border-right: 1px solid $inactive-color;
  float: none;
  border-radius: 0;
}

.orgchart .lines .leftLine {
  border-left: 1px solid $inactive-color;
  float: none;
  border-radius: 0;
}

.orgchart .lines .downLine {
  background-color: $inactive-color;
  margin: 0 auto;
  height: 20px;
  width: 2px;
  float: none;
}


.node-fr-block{
  margin-left: -160px;
}

.node-block-function {
  border: 2px solid $inactive-color;
  border-right: 0px;
  height: 460px;
  background-color: white;
}

.node-block-function-title {
  background-color: $inactive-color;
  color: white;
  padding: 5px;
}

.node-block-function-fio {
  background-color: white;
  padding: 5px;
}

.function-arrow {
  display: inline-block;
  margin-bottom: 50%;
}


.node-card-block{
  border: 2px solid $inactive-color;
  border-left: 0;
  flex: 0 0 400px;
  background-color: white;
  overflow: auto;

  &.active {
    border-color: $--color-primary;
  }
}
